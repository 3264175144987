const Contact = ({ contactRef }: any) => {
  return (
    <div
      className="bg-[#E8F4FA] min-h-[calc(100vh-96px)] flex flex-col justify-center"
      ref={contactRef}
    >
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="sm:text-center w-full sm:w-2/3 mx-auto">
          <h2 className="text-3xl font-semibold text-black sm:text-3xl !leading-[51px]">
            CreativeX is more than a web development company!
          </h2>
        </div>
        <form
          action="#"
          className="mt-20 sm:mx-auto sm:max-w-2xl sm:flex"
        >
          <div className="min-w-0 flex-1">
            <label htmlFor="cta-email" className="sr-only">
              Email address
            </label>
            <input
              id="cta-email"
              type="email"
              className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
              placeholder="Enter your email"
            />
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-3">
            <button
              type="submit"
              className="block w-full rounded-md border border-transparent px-5 py-3 bg-black text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black sm:px-10"
            >
              Contact us
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
