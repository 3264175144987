import { useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}
const Navbar = ({ navigation }: any) => {
  const [currentSection, setCurrentSection] = useState(null);
  const scrollToSection = (ref: any) => {
    const yOffset = -96; // Adjust this to your needs
    const y =
      ref.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  const handleScroll = () => {
    navigation.forEach((item: any) => {
      if (item.ref.current) {
        const top = item.ref.current.getBoundingClientRect().top;
        if (top >= 0 && top <= window.innerHeight * 0.5) {
          setCurrentSection(item.name);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Disclosure as="nav" className="bg-white sticky top-0 z-10">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
            <div className="relative flex h-24 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center sm:items-stretch justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-14 w-auto"
                    src="/images/logo.png"
                    alt="Your Company"
                  />
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-8">
                    {navigation.map((item: any) => (
                      <a
                        key={item.name}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection(item.ref);
                        }}
                        className={classNames(
                          currentSection === item.name
                            ? 'bg-primary text-white'
                            : 'text-black hover:bg-indigo-500 hover:text-white',
                          'rounded-md px-6 py-2 text-base font-medium hover:cursor-pointer'
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden border">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item: any) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection(item.ref);
                  }}
                  className={classNames(
                    currentSection === item.name
                      ? 'bg-primary text-white'
                      : 'text-black',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default Navbar;
