import { useRef } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './components/About';
import Home from './components/Home';
import Contact from './components/Contact';
import './App.css';

const App = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const navigation = [
    { name: 'Home', href: '#', current: true, ref: homeRef },
    { name: 'About us', href: '#', current: false, ref: aboutRef },
    {
      name: 'Contact us',
      href: '#',
      current: false,
      ref: contactRef,
    },
  ];
  return (
    <div className="App font-poppins">
      <Navbar navigation={navigation} />
      <Home homeRef={homeRef} navigation={navigation} />
      <About aboutRef={aboutRef} />
      <Contact contactRef={contactRef} />
      <Footer />
    </div>
  );
};

export default App;
