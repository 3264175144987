import vector2 from '../svg/vector2.svg';
import vector4 from '../svg/vector4.png';
import vector5 from '../svg/vector5.png';
import {
  ComputerDesktopIcon,
  GlobeAltIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
const About = ({ aboutRef }: any) => {
  return (
    <div className="bg-white min-h-[calc(100vh-96px)]" ref={aboutRef}>
      <div className="relative isolate ">
        <div className=" border-b ">
          <div className="sm:mx-auto max-w-xs sm:max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row justify-around py-14 gap-14 mx-auto ">
            <div className="flex gap-5 items-center">
              <div className=" p-4 bg-[#EFEAFF] rounded-xl">
                <ComputerDesktopIcon className="w-8 h-8 text-violet-500" />
              </div>
              <span className="font-medium text-xl">
                Web Development
              </span>
            </div>
            <div className="flex gap-5 items-center">
              <div className=" p-4 bg-[#ECFFDA] rounded-xl">
                <PencilSquareIcon className="w-8 h-8 text-green-500" />
              </div>
              <span className="font-medium text-xl">
                UI/UX Design
              </span>
            </div>
            <div className="flex gap-5 items-center">
              <div className=" p-4 bg-[#DAE6FF] rounded-xl">
                <GlobeAltIcon className="w-8 h-8 text-blue-500" />
              </div>
              <span className="font-medium text-xl">
                Web maintenance
              </span>
            </div>
          </div>
        </div>
        <div className="px-6 lg:px-8 ">
          <div className="flex flex-col justify-center mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-16 gap-12">
            <div className="flex flex-col gap-6 sm:gap-12 w-full sm:w-1/2 mx-auto">
              <p className="font-semibold text-4xl">
                Welcome to CreativeX!
              </p>
              <p className="text-base leading-7 text-[#5C5C5C]">
                Where innovation meets expertise to redefine the
                digital landscape. Established in 20, we are a
                passionate team of web developers, designers, and tech
                enthusiasts dedicated to pushing boundaries and
                delivering exceptional online experiences.
              </p>
            </div>
            <div className="flex flex-col-reverse sm:flex-row justify-center items-center gap-8 sm:gap-0">
              <div className="w-full sm:w-1/2 text-left flex flex-col gap-6 ">
                <div>
                  <p className="font-bold text-6xl leading-none sm:leading-[96px]">
                    Our <span className="text-primary">Mission</span>
                  </p>
                </div>
                <p className="text-base leading-8 text-[#5C5C5C] w-full sm:w-[78%]">
                  Our mission is simple yet profound: to empower
                  businesses and individuals by harnessing the full
                  potential of the digital realm. We believe that
                  every website is a canvas waiting to be painted with
                  creativity and functionality.
                </p>
              </div>
              <div className="w-full sm:w-1/2 self-center">
                <img
                  src={vector4}
                  alt=""
                  className=" translate-y-8"
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center items-center">
              <div className="w-full sm:w-1/2 self-center">
                <img
                  src={vector5}
                  alt=""
                  className=" translate-y-8"
                />
              </div>
              <div className="w-full sm:w-1/2 text-left flex flex-col gap-6 sm:ml-24">
                <div>
                  <p className="font-bold text-6xl leading-none sm:leading-[96px]">
                    Our <span className="text-primary">Approach</span>
                  </p>
                </div>
                <p className="text-base leading-8 text-[#5C5C5C] w-full sm:w-[78%]">
                  At CreativeX, we believe that every project is an
                  opportunity to create something extraordinary. Our
                  approach is built on the pillars of innovation,
                  quality, and client satisfaction.
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-5">
              <div className="w-full sm:w-1/3 bg-[#F4F4F4] px-8 py-8 sm:py-12 rounded-2xl">
                <h2 className=" text-2xl font-medium leading-[42.5px] mb-6">
                  Innovation
                </h2>
                <p className="text-base leading-8 text-[#545454] w-full">
                  We stay at the forefront of technological
                  advancements to bring fresh ideas and innovative
                  solutions to the table. Our curiosity drives us to
                  explore new possibilities, experiment with emerging
                  trends, and incorporate the latest tools into our
                  projects.
                </p>
              </div>
              <div className="w-full sm:w-1/3 bg-[#F4F4F4] px-8 py-8 sm:py-12 rounded-2xl">
                <h2 className=" text-2xl font-medium leading-[42.5px] mb-6">
                  Quality
                </h2>
                <p className="text-base leading-8 text-[#545454] w-full">
                  Excellence is our standard. From the initial concept
                  to the final product, we uphold the highest quality
                  standards in everything we do. Our attention to
                  detail ensures that every line of code, every design
                  element, and every user interaction is polished to
                  perfection.
                </p>
              </div>
              <div className="w-full sm:w-1/3 bg-[#F4F4F4] px-8 py-8 sm:py-12 rounded-2xl">
                <h2 className=" text-2xl font-medium leading-[42.5px] mb-6">
                  Client Satisfaction
                </h2>
                <p className="text-base leading-8 text-[#545454] w-full">
                  Your success is our success. We take the time to
                  understand your goals, challenges, and unique
                  requirements. Our collaborative approach ensures
                  that we deliver results that align with your vision
                  and exceed your expectations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden sm:top-[calc(100%-60rem)] hidden sm:block"
          aria-hidden="true"
        >
          <img
            src={vector2}
            alt=""
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem]  sm:left-[78%] 2xl:left-[75%] sm:w-[60rem] hidden sm:block"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
