export default function Footer() {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1 text-left">
            <img
              className="h-14"
              src="/images/logo.png"
              alt="CreativeX"
            />
            <p className="text-gray-500 text-base ml-3 w-1/2">
              We're your partners in digital transformation.
            </p>
            <p className="text-gray-500 text-base ml-3">
              @creativexcoltd
            </p>
          </div>
          <div className="space-y-6 xl:col-span-1 text-left mt-8">
            <p className="text-gray-500 text-base ml-3 font-semibold">
              Contact us
            </p>
            <p className="text-gray-500 text-base ml-3">
              Together, let's build a future where possibilities are
              limitless, and the digital experience knows no
              boundaries.
            </p>
            <p className="text-gray-500 text-base ml-3">
              +908 89097 890
            </p>
          </div>
        </div>
      </div>
      <div className="my-12 border-t border-gray-200 pt-8">
        <p className="text-base text-gray-400 xl:text-center">
          Copyright &copy; 2019 CreativeX All rights reserved
        </p>
      </div>
    </footer>
  );
}
