import { useRef } from 'react';
import vector1 from '../svg/vector1.svg';
import vector2 from '../svg/vector2.svg';
import vector3 from '../svg/vector3.png';

const Home = ({ homeRef, navigation }: any) => {
  const scrollToSection = (ref: any) => {
    const yOffset = -96; // Adjust this to your needs
    const y =
      ref.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  return (
    <div
      className="bg-[#1090CB1A] min-h-[calc(100vh-96px)]"
      ref={homeRef}
    >
      <div className="relative isolate px-6 lg:px-8 ">
        <div className="flex flex-col sm:flex-row justify-center mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="w-full sm:w-1/2 text-center sm:text-left flex flex-col gap-6 justify-center mt-6 sm:mt-0">
            <div>
              <p className="font-semibold text-4xl sm:text-6xl leading-normal sm:leading-[96px]">
                Empowering Your
              </p>
              <p className="font-bold text-4xl sm:text-6xl text-primary leading-normal sm:leading-[96px]">
                Digital Presence
              </p>
            </div>
            <p className="text-base leading-normal sm:leading-9 text-[#5C5C5C]">
              At CreativeX, we specialize in crafting cutting-edge
              digital experiences that drive your brand's success. Our
              expert team of web developers is dedicated to
              transforming your vision into a stunning and functional
              online reality. Whether you're a startup looking for an
              impactful launch or an established business aiminga to
              revamp your online presence, we have the expertise to
              make it happen.
            </p>
            <div className="flex flex-col sm:flex-row gap-3 sm:gap-6 w-full sm:w-[75%] mt-5">
              <button
                className="bg-primary text-white w-full sm:w-1/2 px-5 py-3 rounded-lg"
                onClick={() => scrollToSection(navigation[2].ref)}
              >
                Contact us
              </button>
              <button
                className="border border-primary text-primary w-full sm:w-1/2 px-5 py-3 rounded-lg"
                onClick={() => scrollToSection(navigation[1].ref)}
              >
                View more
              </button>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <img src={vector3} alt="" className=" -translate-y-16" />
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden sm:top-[calc(100%-50rem)] hidden sm:block"
          aria-hidden="true"
        >
          <img
            src={vector1}
            alt=""
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem]  sm:left-[72%] 2xl:left-[75%] sm:w-[100rem] hidden sm:block"
          />
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden sm:top-[calc(100%-30rem)] hidden sm:block"
          aria-hidden="true"
        >
          <img
            src={vector2}
            alt=""
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 sm:left-[-15rem] sm:w-[70rem] hidden sm:block"
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
